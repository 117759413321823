import React from "react"

import Layout from "../components/layout"

const EnglishPage = ({ location }) => (
    <Layout
        isFormSuccesfull={location.hash.indexOf('#done') !== -1}
        lang='en'
    />
)

export default EnglishPage
